import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app/app.service';

export interface IClinicDayData {
  clinicDaysID: number;
  name: string;
  clinicDaysStatusID: number | null;
  doctorID: number;
}

export interface ILocationData {
  latitude: number | null;
  longitude: number | null;
}

export interface IDoctorDegreeData {
  doctorDegreeID: number;
  name: string;
  doctorID: number;
  description: string;
}

export interface IDoctorProfile {
  affiliatedOrganization: string;
  title: string;
  clinicAddress1: string;
  clinicAddress2: string;
  clinicCity: string;
  clinicDays: IClinicDayData[];
  clinicEmail: string;
  clinicStartTime: string;
  clinicEndTime: string;
  clinicLocation: ILocationData;
  clinicMobileNumber: string;
  clinicPhoneNumber: string;
  clinicProvince: string;
  doctorDegrees: IDoctorDegreeData[];
  doctorID: number;
  isActive: boolean;
  isPublish: boolean;
  issuingAuthority: boolean;
  identityID: number;
  licenceNumberOrPMDCNumber: string;
  numberOfPatientsCheckPerDay: string;
  onlineAppointmentEndTime: string;
  onlineAppointmentStartTime: string;
}

export interface IPublishDoctorProfileRequest {
  identityId: number;
  isPublish: boolean;
  isAdmin: boolean;
}

export interface IPublishDoctorProfileResponse {
  isPublish: boolean;
}

@Injectable()
export class DoctorService {
  public apiPath: string = 'http://localhost:63753/api/v1/doctor';

  constructor(public Http: HttpClient,private appService: AppService) {}

  public getDoctorsList(): Promise<IDoctorProfile[]> {
    return this.Http.get<IDoctorProfile[]>(`${this.appService.apiUrl}doctor/list`)
      .toPromise();
  }

  public add(request: IDoctorProfile): Promise<IDoctorProfile> {
    return this.Http.post<IDoctorProfile>(`${this.appService.apiUrl}doctor`, request)
      .toPromise();
  }

  public publish(request: IPublishDoctorProfileRequest): Promise<IPublishDoctorProfileResponse> {
    return this.Http.post<IPublishDoctorProfileResponse>(`${this.appService.apiUrl}doctor/publishProfile`, request)
      .toPromise();
  }
}
